import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    supports: [],
    support: null,
    loading: false,
    recentArticles: [],
};

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        setSupportLoading: (state, { payload }) => {
            state.loading = payload;
        },
    },
});

const { actions, reducer } = supportSlice;

export const {
    setSupportLoading,
} = actions;

export default reducer;
