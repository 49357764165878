export const CategoryBox = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_bulk_box"
        data-name="vuesax/bulk/box"
        transform="translate(-107.999 -188)"
      >
        <g id="box">
          <path
            id="Vector"
            d="M18.08,5.19,9.04,10.42,0,5.19A4.392,4.392,0,0,1,1.63,3.44L6.97.48a4.7,4.7,0,0,1,4.14,0l5.34,2.96A4.392,4.392,0,0,1,18.08,5.19Z"
            transform="translate(110.96 190)"
            fill={fill}
            opacity="0.4"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M9.61,5.23v9.58a4.332,4.332,0,0,1-2.07-.48L2.2,11.37A4.719,4.719,0,0,1,0,7.64V1.98A4.265,4.265,0,0,1,.57,0Z"
            transform="translate(110.39 195.19)"
            fill={fill}
            opacity="0.6"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M9.61,1.98V7.64a4.719,4.719,0,0,1-2.2,3.73L2.07,14.33A4.332,4.332,0,0,1,0,14.81V5.23L9.04,0A4.265,4.265,0,0,1,9.61,1.98Z"
            transform="translate(120 195.19)"
            fill={fill} // "#8950fc"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(107.999 188)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
