export const Support = ({ fill = '#494b74' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_bulk_sms"
        // data-name="vuesax/bulk/sms"
        transform="translate(-556 -252)"
      >
        <g id="sms">
          <path
            id="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(556 252)"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-2"
            // data-name="Vector"
            d="M15,17H5c-3,0-5-1.5-5-5V5C0,1.5,2,0,5,0H15c3,0,5,1.5,5,5v7C20,15.5,18,17,15,17Z"
            transform="translate(558 255.5)"
            fill={fill}
            opacity="0.4"
          />
          <g id="Group">
            <path
              id="Vector-3"
              // data-name="Vector"
              d="M5.753,4.626a3.717,3.717,0,0,1-2.34-.79l-3.13-2.5a.747.747,0,1,1,.93-1.17l3.13,2.5a2.386,2.386,0,0,0,2.81,0l3.13-2.5a.738.738,0,0,1,1.05.12.738.738,0,0,1-.12,1.05l-3.13,2.5A3.67,3.67,0,0,1,5.753,4.626Z"
              transform="translate(562.247 260.244)"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
