export const Dropdown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="vuesax_bulk_arrow-circle-down"
        // data-name="vuesax/bulk/arrow-circle-down"
        transform="translate(-556 -252)"
      >
        <g id="arrow-circle-down" transform="translate(556 252)">
          <path
            id="Vector"
            d="M13.333,6.667A6.667,6.667,0,1,1,6.667,0,6.667,6.667,0,0,1,13.333,6.667Z"
            transform="translate(1.333 1.333)"
            fill="none"
            opacity="1"
          />
          <path
            id="Vector-2"
            // data-name="Vector"
            d="M2.852,3.352A.494.494,0,0,1,2.5,3.205L.145.852A.5.5,0,0,1,.852.145l2,2,2-2a.5.5,0,1,1,.707.707L3.2,3.205A.494.494,0,0,1,2.852,3.352Z"
            transform="translate(5.148 6.655)"
            fill="white"
          />
          <path
            id="Vector-3"
            // data-name="Vector"
            d="M0,0H16V16H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
