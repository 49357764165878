import { toast } from "react-toastify";
import {
    axios,
    getError,
    createNotifiedConfig,
} from "lib";
import { setSupportLoading } from "store/Slices";


export const createNotifiedUsers = ({ data }) => {
    return async (dispatch) => {
        dispatch(setSupportLoading(true));
        try {
            const { url, config } = createNotifiedConfig();
            //console.log('url, config', url, config);
            const response = await axios.post(url, data, config);
            if (response.status === 200) {
              toast.success("Notified Users Created Successfully!");
              //dispatch(getTickets());
            }
          } catch (error) {
            toast.error(getError(error));
          } finally {
            dispatch(setSupportLoading(false));
          }
    };
};