export const Info = ({ fill = '#494b74' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
    >
      <g id="vuesax_bulk_info-circle" transform="translate(-364 -252)">
        <g id="info-circle" transform="translate(364 252)">
          <path
            id="Vector"
            d="M32.5,16.25A16.25,16.25,0,1,1,16.25,0,16.25,16.25,0,0,1,32.5,16.25Z"
            transform="translate(3.25 3.25)"
            fill={fill}
            opacity="0.4"
          />
          <path
            id="Vector-2"
            d="M1.219,10.563A1.228,1.228,0,0,0,2.438,9.344V1.219A1.228,1.228,0,0,0,1.219,0,1.228,1.228,0,0,0,0,1.219V9.344A1.228,1.228,0,0,0,1.219,10.563Z"
            transform="translate(18.281 11.781)"
            fill={fill}
          />
          <path
            id="Vector-3"
            d="M3.12,1A1.876,1.876,0,0,0,2.779.463,1.876,1.876,0,0,0,2.243.122a1.625,1.625,0,0,0-1.235,0A1.876,1.876,0,0,0,.471.463,1.876,1.876,0,0,0,.13,1,1.618,1.618,0,0,0,0,1.617a1.618,1.618,0,0,0,.13.617,1.677,1.677,0,0,0,.341.536,1.876,1.876,0,0,0,.536.341,1.618,1.618,0,0,0,.618.13,1.618,1.618,0,0,0,.618-.13,1.876,1.876,0,0,0,.536-.341,1.677,1.677,0,0,0,.341-.536,1.618,1.618,0,0,0,.13-.617A1.618,1.618,0,0,0,3.12,1Z"
            transform="translate(17.875 24.383)"
            fill={fill}
          />
          <path id="Vector-4" d="M0,0H39V39H0Z" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};
